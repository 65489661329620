import React, { FC, ReactNode } from "react"

export const defaultTitle = "Business Strategic Consulting"
export const defaultText = `There are many variations of passages of Lorem Ipsum available, but the
majority have suffered alteration in some form by a ac injected humour or
randomised words which do not look even slightly believable. If you are
going to use a passage of that Lorem Ipsum, you need to be sure there
is not anything embarrassing hidden in the middle of text. \n\n
\n
There are many variations of passages of Lorem Ipsum available, but the
majority have suffered alteration in some`
export const defaultImageSrc = "image/chaz-mcgregor-f66-oTghELc-unsplash.jpg"

interface Props {
  title?: ReactNode
  imageSrc?: string
  imageAlt?: string
}

const Article: FC<Props> = ({
  children = defaultText,
  title,
  imageSrc,
  imageAlt,
}) => (
  <div className="service-details">
    <div className="container">
      {imageSrc && (
        <div className="bd-img">
          <img src={imageSrc} alt={imageAlt} />
        </div>
      )}
      <h2 className="bd-title">{title}</h2>
      <div className="bd-text">{children}</div>
    </div>
  </div>
)

export default Article
