import React, { FC, ReactNode } from "react"

export const defaultTitle = "Headmark"
export const defaultBackgroundSrc =
  "image/chaz-mcgregor-f66-oTghELc-unsplash.jpg"

interface Props {
  title?: ReactNode
  breadcrumbs?: ReactNode
  backgroundSrc?: string
}
const PageBanner: FC<Props> = ({
  title = defaultTitle,
  breadcrumbs,
  backgroundSrc = defaultBackgroundSrc,
}) => (
  <div
    className="breadcrumb-area bg-with-black"
    style={{ backgroundImage: `url('${backgroundSrc}')` }}
  >
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2 className="page-name">{title}</h2>
          {breadcrumbs && <div className="breadcrumb">{breadcrumbs}</div>}
        </div>
      </div>
    </div>
  </div>
)

export default PageBanner
