import React from "react"

import Layout from "../components/layout"
import PageBanner from "../components/pageBanner"
import Article from "../components/article"
import SEO from "../components/seo"

const ServicesPage = () => (
  <Layout>
    <SEO title="Services" />
    <PageBanner title="Services" />
    <Article>
      <p>
        We have skilled personnel in Program and Project Management, Business
        Analysts, Operations Analysts, Integrated Logistics Managers,
        Requirements Managers and Specialist Engineers ready to deliver for you
        a wealth of expertise in all aspects of:
        <ul style={{ marginTop: "2rem" }}>
          <li>Information Warfare</li>
          <li>Intelligence, Surveillance and Reconnaissance</li>
          <li>Electronic Warfare</li>
          <li>Information and Communications Technology</li>
          <li>Communications</li>
          <li>Cyber</li>
          <li>Command and Control</li>
          <li>
            Across Coalition, Joint and Single Service environments from the
            Strategic to Tactical level
          </li>
        </ul>
      </p>
    </Article>
  </Layout>
)

export default ServicesPage
